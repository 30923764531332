import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterModule } from '@angular/router';

import { AuthFacade } from '@yuno/angular-auth';
import { YunoUserRolesEnum } from '@yuno/api/interface';
import { LoadersModule } from '@yuno/project-atlas/ui';
import { YunoUiHeaderComponent } from '@yuno/yuno/features/ui';

import { AppStore } from '../../data-access';
import { FunctionsButtonComponent } from '../button/button.component';

@Component({
	selector: 'yuno-feat-functions',
	imports: [RouterModule, FunctionsButtonComponent, LoadersModule, YunoUiHeaderComponent],
	template: `
		<yuno-ui-header [title]="'Functions'" />

		@if ($app()) {
			<div
				class="flex flex-row flex-wrap items-start justify-between gap-8 sm:justify-start sm:gap-16">
				<yuno-feat-functions-button [link]="['view']">
					<svg
						icon
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						stroke-width="1"
						stroke="#fff"
						class="h-8 w-8">
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
					</svg>
					View Atlas
				</yuno-feat-functions-button>

				@if (hasMinimalRole(userRoles.ATLASINTERNAL, $appRole())) {
					<yuno-feat-functions-button [link]="['view-internal']">
						<svg
							icon
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1"
							stroke="#fff"
							class="h-8 w-8">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
						</svg>
						View Internal
					</yuno-feat-functions-button>
				}

				<yuno-feat-functions-button
					[link]="['participate']"
					colorClass="bg-yuno-portal-green">
					<svg icon xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none">
						<g stroke="#fff" stroke-width="1.5">
							<path
								d="m21.99 12.963.525 3.861a.212.212 0 0 1-.053.168l-.07.046a.141.141 0 0 1-.037.016.224.224 0 0 1-.17-.02l-.006-.005-.006-.004-3.837-2.55-.232-.154-.276.035-.2.025-.013.002a14.27 14.27 0 0 1-1.75.117c-2.603-.001-4.931-.787-6.591-2.017-1.663-1.231-2.616-2.87-2.616-4.608 0-1.737.956-3.376 2.62-4.608 1.664-1.232 3.994-2.017 6.593-2.017 2.598 0 4.929.785 6.593 2.017 1.655 1.225 2.61 2.854 2.62 4.58-.139 1.768-1.007 3.199-2.707 4.343l-.026.018-.024.019-.062.05-.332.265.057.421Z" />
							<path
								d="M5.008 9.113c-1.8 1.337-2.912 3.175-2.912 5.2.075 1 .387 1.975.925 2.837a6.286 6.286 0 0 0 2.112 2.1l.063.05-.525 3.863a.88.88 0 0 0 .087.537.96.96 0 0 0 .363.413c.162.1.337.15.525.15a.997.997 0 0 0 .525-.163l3.837-2.55.2.025c.613.088 1.238.125 1.85.125 3.713 0 6.95-1.512 8.663-3.738" />
						</g>
						<defs>
							<clipPath id="a">
								<path fill="#fff" d="M.833.5h25v25h-25z" />
							</clipPath>
						</defs>
					</svg>
					Participates
				</yuno-feat-functions-button>
				<yuno-feat-functions-button
					[link]="['markers-geophoto']"
					colorClass="bg-yuno-portal-orange">
					<svg icon xmlns="http://www.w3.org/2000/svg" width="27" height="22" fill="none">
						<path
							stroke="#fff"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="1.5"
							d="M7.035 3.94c-.231.345-.54.638-.903.86a3.07 3.07 0 0 1-1.201.418c-.487.066-.97.136-1.454.213-1.35.212-2.31 1.335-2.31 2.629v10.213c0 .723.304 1.417.845 1.928a2.971 2.971 0 0 0 2.04.799h19.23a2.97 2.97 0 0 0 2.04-.799 2.654 2.654 0 0 0 .845-1.928V8.06c0-1.294-.962-2.417-2.31-2.63a64.63 64.63 0 0 0-1.454-.212 3.07 3.07 0 0 1-1.201-.419 2.884 2.884 0 0 1-.902-.86l-1.054-1.595a2.742 2.742 0 0 0-.95-.886 2.927 2.927 0 0 0-1.276-.373 66.132 66.132 0 0 0-6.707 0c-.45.026-.889.153-1.276.373-.387.219-.713.523-.95.886L7.035 3.94Z" />
						<path
							stroke="#fff"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="1.5"
							d="M19.436 11.91c0 1.446-.608 2.833-1.69 3.856-1.082 1.023-2.55 1.598-4.08 1.598-1.53 0-2.997-.575-4.079-1.598-1.082-1.023-1.69-2.41-1.69-3.857 0-1.446.608-2.834 1.69-3.857 1.082-1.023 2.55-1.597 4.08-1.597 1.53 0 2.997.574 4.08 1.597 1.081 1.023 1.689 2.41 1.689 3.857Zm2.884-2.728h.01v.01h-.01v-.01Z" />
					</svg>
					GeoPhotos
				</yuno-feat-functions-button>
			</div>
		} @else {
			<yuno-spinner></yuno-spinner>
		}
	`,
	styles: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YunoMobileFunctionsComponent {
	private readonly authFacade = inject(AuthFacade);
	protected readonly userRoles = YunoUserRolesEnum;

	route = inject(ActivatedRoute);
	store = inject(AppStore);

	$app = this.store.data;
	$appRole = toSignal(this.authFacade.appRole$);

	hasMinimalRole = this.authFacade.userHasMinimalAppRole;
}
