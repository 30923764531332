import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
	selector: 'yuno-feat-functions-button',
	imports: [NgClass, RouterLink],
	template: `
		<a
			class="h-auto w-[4.5rem] transition-opacity duration-300 ease-linear hover:opacity-75"
			[routerLink]="link">
			<div class="flex h-40 flex-col items-center justify-center gap-4">
				<div
					class="flex h-[4.5rem] w-[4.5rem] items-center justify-center rounded-full"
					[ngClass]="colorClass">
					<ng-content select="[icon]"></ng-content>
				</div>
				<span class="w-full text-center">
					<ng-content></ng-content>
				</span>
			</div>
		</a>
	`,
	styles: ``,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FunctionsButtonComponent {
	@Input({ required: true }) link: string[] = [];
	@Input() colorClass = 'bg-blue-500';
}
